import { Link } from 'gatsby'
import React from 'react'
import Layout from '../components/Layout'


export default function credits() {
    return (
        <Layout>
            <div>
                <h1>Credits</h1>
                <h3>Thanks to</h3>
                <p><u>Salc1</u> and his Staff Team for gathering all the data.</p>
                <p><u>smoke.oui.d#0001</u> for creating the <Link to="/inv-viewer">Inv-Viewer</Link></p>
                <p><u>Novato#0789</u> for early render and the spectator Server(offline now)</p>
                <p><u>Philipp_DE#1337</u> for creating the <Link to="/render">Render</Link>, <Link to="/region-requester">Region Requester</Link> and sign and map data</p>
            </div>
        </Layout>
    )
}
